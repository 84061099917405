<script>
export default {

}
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          {{ new Date().getFullYear() }} &copy; Dashonic.
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            Crafted with <i class="mdi mdi-heart text-danger" /> by <a
              href="https://Pichforest.com/"
              target="_blank"
              class="text-reset"
            >Pichforest</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
